import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.svg'
import _imports_1 from '@/assets/icons/language_black_24dp@2x.png'
import _imports_2 from '@/assets/icons/white_arrow_right_24px.png'


const _hoisted_1 = {
  id: "confirm_candidate",
  class: "menu-container fixed flex-center min-m-none"
}
const _hoisted_2 = { class: "menu-navbar-for-test" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  src: _imports_0,
  alt: "MyCulture logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}
const _hoisted_5 = {
  class: "content-46w",
  style: {"box-shadow":"none"}
}
const _hoisted_6 = { class: "flex-mid" }
const _hoisted_7 = {
  key: 0,
  class: "flex-mid company-logo-image-container"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  src: _imports_0,
  alt: "MyCulture logo",
  class: "company-logo-image max-m-none",
  style: {"width":"18rem"}
}
const _hoisted_10 = { class: "fw-700 fs-22 center min-m-mt-32" }
const _hoisted_11 = {
  class: "fs-14 center mt-24 pr-20 pl-20",
  style: {"color":"#696c80"}
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "flex-center mt-40" }
const _hoisted_14 = {
  class: "input-container-02 mr-8",
  style: {"width":"50%"}
}
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "label" }
const _hoisted_17 = {
  class: "input-container-02 ml-8",
  style: {"width":"50%"}
}
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "input-container-02 mt-24" }
const _hoisted_21 = { class: "label" }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "input-container-02 mt-40" }
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "input-container-02 mt-24" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = {
  class: "mt-24 relative",
  style: {"width":"100%"}
}
const _hoisted_29 = {
  key: 2,
  class: "select-with-icon mt-16"
}
const _hoisted_30 = {
  class: "fs-14 fc-1B1C1E",
  style: `white-space: break-spaces;}`
}
const _hoisted_31 = {
  target: "_window",
  href: "https://www.myculture.ai/terms-and-conditions"
}
const _hoisted_32 = {
  class: "fs-14 fc-1B1C1E",
  style: `white-space: break-spaces`
}
const _hoisted_33 = {
  key: 4,
  class: "btn-create btn-progress flex-mid mt-40"
}
const _hoisted_34 = {
  key: 1,
  class: "assessment-card-container on-top-mobile-screen"
}
const _hoisted_35 = { class: "assessment-content box-card mb-32" }
const _hoisted_36 = { class: "width-max-520" }
const _hoisted_37 = { class: "flex-mid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.companyLogoUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.companyLogoUrl,
              alt: "Happily logo",
              class: "company-logo-image",
              style: {"width":"18rem"}
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ])
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "mock-menu-container min-m-none" }, null, -1)),
    (!_ctx.completedStatus)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "login_page",
          class: _normalizeClass(["card-96w-with-header flex-mid on-top-mobile-screen", `${_ctx.lang == 'th' ? 'font-Kanit' : 'Urbanist'}`])
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.companyLogoUrl)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: _ctx.companyLogoUrl,
                      alt: "Happily logo",
                      class: "company-logo-image max-m-none"
                    }, null, 8, _hoisted_8)
                  ]))
                : (_openBlock(), _createElementBlock("img", _hoisted_9))
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("login.Please_confirm_who_you")), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("createAccount.Let_s_get_started_with")), 1),
            (_ctx.isSecured)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _withDirectives(_createElementVNode("input", {
                        class: _normalizeClass(`input`),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.firstname) = $event)),
                        type: "email",
                        placeholder: _ctx.$t('createAccount.Enter_your_first_name')
                      }, null, 8, _hoisted_15), [
                        [_vModelText, _ctx.form.firstname]
                      ]),
                      _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("placeholder.First_name")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _withDirectives(_createElementVNode("input", {
                        class: _normalizeClass(`input`),
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.lastname) = $event)),
                        type: "email",
                        placeholder: _ctx.$t('createAccount.Enter_your_last_name')
                      }, null, 8, _hoisted_18), [
                        [_vModelText, _ctx.form.lastname]
                      ]),
                      _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("placeholder.Last_name")), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(`input`),
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
                      type: "email",
                      readonly: "",
                      placeholder: "example@email.com"
                    }, null, 512), [
                      [_vModelText, _ctx.form.email]
                    ]),
                    _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("placeholder.email")), 1)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(`input`),
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.fullname) = $event)),
                      type: "email",
                      placeholder: _ctx.$t('placeholder.Enter_your_name')
                    }, null, 8, _hoisted_24), [
                      [_vModelText, _ctx.form.fullname]
                    ]),
                    _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("placeholder.full_name")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(`input`),
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.email) = $event)),
                      type: "email",
                      placeholder: "example@email.com"
                    }, null, 512), [
                      [_vModelText, _ctx.form.email]
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.error) + " ", 1),
                    _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("placeholder.email")), 1)
                  ])
                ])),
            _createElementVNode("div", _hoisted_28, [
              _createVNode(_component_custom_select, {
                ref: "selectedLanguage",
                value: _ctx.selectedLanguage,
                options: _ctx.languages,
                placeholder: _ctx.$t('createAccount.Choose_language'),
                label: _ctx.$t('createAccount.Language'),
                onInput: _ctx.handleLanguageInput,
                disableAll: true
              }, null, 8, ["value", "options", "placeholder", "label", "onInput"])
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.lang,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.lang) = $event)),
                    class: "select-login e-input-52",
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isLangEnEnabled)
                        ? (_openBlock(), _createBlock(_component_el_option, {
                            key: 0,
                            label: "English",
                            value: "en"
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.isLangThEnabled)
                        ? (_openBlock(), _createBlock(_component_el_option, {
                            key: 1,
                            label: "ภาษาไทย",
                            value: "th"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "icon-left flex-mid" }, [
                    _createElementVNode("img", {
                      src: _imports_1,
                      class: "icon-20",
                      alt: "icons_language_black_24dp@2x"
                    })
                  ], -1))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_el_checkbox, {
                modelValue: _ctx.form.acceptTerrms,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.acceptTerrms) = $event)),
                class: "checkbox-custom mt-20"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_30, [
                    _createTextVNode(_toDisplayString(_ctx.$t("login.I_have_read_and")), 1),
                    _createElementVNode("a", _hoisted_31, _toDisplayString(_ctx.$t("login.terms_of_use")), 1)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_checkbox, {
                modelValue: _ctx.form.sendFreeStuff,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.sendFreeStuff) = $event)),
                class: "checkbox-custom"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("login.Send_me_free_stuff")), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            (_ctx.publishSubmitStatus)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass(["btn02 btn-primary mt-24", { 'btn02-disable': !_ctx.validateFormStatus }]),
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t("btn.Continue")) + " ", 1),
                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                    class: "arrow-right",
                    src: _imports_2,
                    alt: "icons_white_arrow_right_24px"
                  }, null, -1))
                ], 2))
              : (_openBlock(), _createElementBlock("div", _hoisted_33, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Continue")), 1),
                  _cache[12] || (_cache[12] = _createElementVNode("svg", {
                    version: "1.1",
                    id: "L9",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    viewBox: "0 0 100 100",
                    "enable-background": "new 0 0 0 0",
                    "xml:space": "preserve",
                    width: "24px",
                    height: "24px"
                  }, [
                    _createElementVNode("path", {
                      fill: "#fff",
                      d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        attributeType: "XML",
                        type: "rotate",
                        dur: "1s",
                        from: "0 50 50",
                        to: "360 50 50",
                        repeatCount: "indefinite"
                      })
                    ])
                  ], -1))
                ]))
          ])
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _cache[13] || (_cache[13] = _createElementVNode("div", {
                class: "card-title",
                style: {"margin-top":"75px"}
              }, " You’ve already completed this assessment ", -1)),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "card-description mt-12" }, " Thank you for taking the time to complete the assessment with us. Your responses have already been submitted. ", -1)),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "card-description mt-32" }, " If you have any questions, please contact us at support@happily.ai ", -1)),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", {
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                  class: "mx-auto btn btn-50 btn-primary",
                  style: {"margin-top":"8rem"}
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                ])
              ])
            ])
          ])
        ]))
  ], 64))
}